<template lang="">
    <div v-bind:id="'modal-'+idform+' -form'" class="modal modal-AG2R">
        <!-- Modal content -->
        <div class="modal-content modal-content-formulaire">
            <span class="close">&times;</span>
            <div class="modal-form">
                <h2>Posez votre question</h2>
                <form method="post" v-bind:id="'form-'+ idform">
                    <input type="hidden" name="form_id" v-bind:id="'form-id-'+ idform+' -form'"
                        v-bind:value="idform+'-form'" />
                    <input type="hidden" name="theme" v-bind:id="'theme-'+ idform+' -form'"
                        value="Formulaire AG2R">
                    <input type="hidden" name="numadd" v-bind:id="'numadd-'+ idform+'-form'" value="Non-renseigne" />
                    <table>
                        <tr>
                            <td>
                                <input type="text" class="nom-form" name="nom" v-bind:id="'nom-'+ idform+' -form'"
                                    placeholder="Entrez votre Nom" required />
                            </td>
                            <td>
                                <input type="text" class="prenom-form" name="prenom"
                                    v-bind:id="'prenom-'+ idform+' -form'" placeholder="Entrez votre prenom" required />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" class="ville-form" name="ville-salon"
                                    v-bind:id="'ville-'+ idform+' -form'"
                                    placeholder="Entrez la ville de votre salon" />
                            </td>
                            <td>
                                <input type="text" class="salon-form" name="nom-salon"
                                    v-bind:id="'salon-'+ idform+' -form'" placeholder="Entrez le nom de votre salon" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" class="telephone-form" name="telephone"
                                    v-bind:id="'telephone-'+ idform+' -form'" placeholder="Entrez votre n° telephone"
                                    required />
                            </td>
                            <td>
                                <select name="situation" v-bind:id="'situation-'+ idform+' -form'">
                                    <option value="Apprenti">Apprenti</option>
                                    <option value="Salarie">Salarié</option>
                                    <option value="Chef d'Entreprise">Chef d'Entreprise</option>
                                    <option value="Indépendant">Indépendant</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <input type="email" class="email-form" name="email"
                                    v-bind:id="'email-'+ idform+' -form'" placeholder="Entrez votre adresse e-mail"
                                    required />
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <textarea rows="4" cols="50" class="question-form" name="question"
                                    v-bind:id="'question-'+ idform+' -form'" placeholder="Posez votre question"
                                    required></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <input type="checkbox" v-bind:id="'RGPD-'+ idform+' -form'" name="RGPD" value="Oui"
                                    class="input-RGPD">
                                <label for="RGPD">En soumettant ce formulaire, j’accepte que les informations saisies
                                    soient utilisées,
                                    exploitées, traitées dans le cadre de cette demande.</label>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <input type="submit" value="Envoyer !" v-bind:id="'submit-'+ idform+' -form'"
                                    class="submit" />
                            </td>
                        </tr>

                    </table>

                </form>
            </div>
            <div class="modal-form-validation">
                <img src="../assets/img/Illustrations/email-envoye.svg" class="img-valid-mail">
                <h2>Formulaire envoyé</h2>
                <a href="#home" class="form-retour">Retour à l'accueil</a>
            </div>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery'


    export default {
        name: 'formemail',
        props: {
            idform: {
                type: String,
            },
            activeEmail: {
                type: Boolean,
            },
            toggleModalEmail: {
                type: Function,
            }
        },
        methods: {
            resetform: function () {
                $('.form_email input[email]').val('');
            }
        },
    }
</script>
<style lang="">

</style>