<template lang="">
    <div v-bind:id="'modal-'+ idform" class="modal form_email">
        <!-- Modal content -->
        <div class="modal-content">
            <span class="close" v-on:click="toggleModalEmail">&times;</span>
            <div class="modal-form">
                <img src="../assets/img/Illustrations/mailbox.svg">
                <h2>Vous désirez en savoir plus ?</h2>
                <h3>Nous vous envoyons tout par e-mail</h3>
                <i>En plus c'est plus écologique !</i>
                <form method="post" v-bind:id="'form-'+ idform" v-on:submit.prevent="submit">
                    <input type="email" placeholder="Entrez votre adresse e-mail" required
                        v-model="email_value" />
                    <input type="submit" value="Valider" class="submit" v-on:click="addEmail()" />
                </form>
            </div>
            <div class="modal-form-validation">
                <img src="../assets/img/Illustrations/email-envoye.svg" class="img-valid-mail">
                <h2>E-mail envoyé !</h2>
                <a href="#home" class="form-retour" v-on:click="toggleModalEmail">Retour à l'accueil</a>
            </div>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery'
    import axios from 'axios'


    export default {
        name: 'formemail',
        props: {
            idform: {
                type: String,
            },
            activeEmail: {
                type: Boolean,
            },
            toggleModalEmail: {
                type: Function,
            }
        },
        data() {
            return {
                email_value: "",
            }
        },
        methods: {
            addEmail: function () {
                if (this.email_value != "") {
                    axios.post("./ajax/ajaxfile.php", {
                        action: 'insert',
                        email: this.email_value,
                        id_form: this.idform,
                    }).then(function (response) {
                        $('.form_email form').trigger("reset");
                        $('.form_email .modal-form-validation').show();
                        $('.form_email .modal-form').hide();
                        console.log(response);
                    })
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    $color-vert:#43E524;
    $color-bleu:#13ecbc;
    $color-rose:#ff748e;
    $color-gris:#9b9b9b;
    $color-rouge:#f76d6d;
    $color-jaune:#f6d12a;

    .modal {
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.5) !important;
        -webkit-animation-name: fadeIn !important;
        -webkit-animation-duration: 1s !important;
        animation-name: fadeIn;
        animation-duration: 1s;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .close {
        font-size: 50px;
        position: absolute;
        top: 0;
        right: 0;
        color: #9b9b9b;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    .modal-content {
        z-index: 10000;
        position: fixed;
        background-color: #fefefe;
        width: 50% !important;
        -webkit-animation-name: slideIn !important;
        -webkit-animation-duration: 1s !important;
        animation-name: slideIn !important;
        animation-duration: 1s !important;

        img {
            margin: 20px auto;
            width: 150px;
            display: block;

        }

        .img-valid-mail {
            width: 350px;
            height: auto;
            margin:0 auto;
        }

        h2 {
            text-align: center;
            color: $color-rouge;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            font-size: 25px;

        }

        h3 {
            text-align: center;
            color: $color-rouge;
            font-size: 20px;
            font-family: 'Montserrat', sans-serif;
        }

        i {
            text-align: center;
            color: $color-gris;
            font-style: normal;
            display: block;
        }

        input {
            padding: 12px;
            margin-left: 6%;
            width: 40%;
            margin-top: 15px;
            margin-bottom: 10px;
            font-size: 12px;
            border-radius: 0px !important;
            border: solid 1px $color-gris !important;
            text-shadow: none !important;
        }

        .submit {
            background-color: $color-rouge;
            color: white;
            border: solid 1px $color-rouge !important;
            font-weight: bold;
            transition: all ease-in-out 0.5s;
            -webkit-appearance:none;

            &:hover {
                background-color: white;
                color: $color-rouge;
                -webkit-appearance:none;
            }

        }

        .modal-form-validation {
            display: none;

            h2 {
                margin-top: 25px;
            }

            a {
                text-decoration: none;
                font-size: 20px;
                text-align: center;
                display: block;
                color: $color-gris;
                margin-bottom: 25px;
                margin-top: 25px;

                &:hover {
                    color: rgb(55, 55, 55);
                    text-decoration: none;
                }
            }

        }
    }

    @-webkit-keyframes fadeIn {
        from {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }
</style>