<template lang="">

        <div id="select">
            <img src="../assets/img/Logo/Logo-moncoiffeursengage.svg" alt="Logo mon coiffeur s'engage">
            <div class="grid">
                <a class="btn-sante btn-rose" v-on:click="transitionSante()">
                    <div class="texte">Prévention<br> Santé</div>
                </a>
                <a class="btn-dev-durable btn-vert" v-on:click="transitionDurable()">
                    <div class="texte">Label
                        <br>Développement<br>
                        Durable</div>
                </a>
                <a class="btn-aide-financiere btn-bleu" v-on:click="transitionAide()">
                    <div class="texte">Action
                        Sociale</div>
                </a>
                <a class="btn-salon btn-bleu" v-on:click="transitionSalon()">
                    <div class="texte">Améliorer<br> mon salon</div>
                </a>
                <a class="btn-mutuelle btn-rose" v-on:click="transitionMutuelle()">
                    <div class="texte">Protection Sociale<br>
                        Prévoyance Santé</div>
                </a>
                <a class="btn-emploi btn-vert" v-on:click="transitionEmploi()">
                    <div class="texte">Emploi /<br> Formation</div>
                </a>
            </div>
            <a href="#home" class="back">Revenir a l'accueil</a> 
            
        </div>
         <transitionillu />
</template>
<script>
    import $ from 'jquery'
    import transitionillu from '../components/transition-illu.vue'

    export default {
        name: "select",
        components: {
            transitionillu,
        },
        methods: {
            transitionSante: function () {  
                $('#Sante-Transition').show().addClass('step1');
                setTimeout(function(){ $('#Sante-Transition').removeClass('step1').addClass('step2');window.location = "#Sante-Questions";  }, 3000);
                setTimeout(function(){ $('#Sante-Transition').hide().removeClass('step2')}, 5000);

            },
            transitionDurable: function () { 
                $('#Dev-Durable-Transition').show().addClass('step1');
                setTimeout(function(){ $('#Dev-Durable-Transition').removeClass('step1').addClass('step2');window.location = "#Dev-Durable-Questions"; }, 3000);
                setTimeout(function(){ $('#Dev-Durable-Transition').hide().removeClass('step2')}, 5000);
            },
            transitionAide: function () { 
                $('#Aide-Financiere-Transition').show().addClass('step1');
                setTimeout(function(){ $('#Aide-Financiere-Transition').removeClass('step1').addClass('step2'); window.location = "#AF-Q2"; }, 3000);
                setTimeout(function(){ $('#Aide-Financiere-Transition').hide().removeClass('step2')}, 5000);
            },
            transitionSalon: function () { 
                $('#Ameliorer-Salon-Transition').show().addClass('step1');
                setTimeout(function(){ $('#Ameliorer-Salon-Transition').removeClass('step1').addClass('step2'); window.location = "#Ameliorer-Salon-Questions"; }, 3000);
                setTimeout(function(){ $('#Ameliorer-Salon-Transition').hide().removeClass('step2')}, 5000);
            },
            transitionMutuelle: function () { 
                $('#Mutuelle-Assurance-Transition').show().addClass('step1');
                setTimeout(function(){ $('#Mutuelle-Assurance-Transition').removeClass('step1').addClass('step2');window.location.href = "https://www.ag2rlamondiale.fr/coiffure"; },2500);
                setTimeout(function(){ $('#Mutuelle-Assurance-Transition').hide().removeClass('step2')}, 5000);
            },
            transitionEmploi: function () { 
                $('#Emploi-Formation-Transition').show().addClass('step1');
                setTimeout(function(){ $('#Emploi-Formation-Transition').removeClass('step1').addClass('step2');window.location = "#Emploi-Formation-Questions"; }, 3000);
                setTimeout(function(){ $('#Emploi-Formation-Transition').hide().removeClass('step2')}, 5000);
            }

        }
    }
</script>
<style lang="scss">
    $color-vert:#43E524;
    $color-bleu:#13ecbc;
    $color-rose:#ff748e;
    $color-gris:#9b9b9b;
    $color-rouge:#f76d6d;
    $color-jaune:#f6d12a;

    .back {
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        margin: 0 auto;
        display: flex;
        margin-top: 15px;
        flex-direction: column;
        text-decoration: none;


        &:after {
            content: "";
            width: 0px;
            height: 3px;
            display: block;
            background-color: white;
            transition: all ease-in-out 0.5s
        }

        &:hover {
            text-decoration: none;

            &:after {
                width: 100%
            }
        }
    }


    #select {
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 100vh;
        width: 100%;

        img {
            width: 20%;
            margin: 20px auto;
        }

        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 15px 15px;
            width: 70%;
            margin: 0 auto;

            a {
                transition: all 500ms;
                -webkit-transition: all 500ms;
                position: relative;
                border: solid 2px white;
                width: 100%;
                padding-bottom: 100%;
                cursor: pointer;

                .texte {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    text-decoration: none;
                    font-weight: 800;
                    font-size: 20px;
                    text-align: center;
                    color: white;
                    text-transform: uppercase;
                }
            }
        }

        .btn-rose:hover {
            background-color: $color-rose;
        }

        .btn-bleu:hover {
            background-color: $color-bleu;
        }

        .btn-vert:hover {
            background-color: $color-vert;
        }
    }

    .slide-enter-active,
    .slide-leave-enter {
        transform: translateX(0);
        transition: all .3s linear;
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(100%);
    }
</style>