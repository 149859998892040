<template lang="">
    <section id="Sante-Transition" class="transition">
        <h3> Prévention Santé </h3>
        <img src="../assets/img/Illustrations/Sante.svg">
    </section>

    <section id="Dev-Durable-Transition" class="transition">
        <h3> Label / Developpement Durable </h3>
        <img src="../assets/img/Illustrations/developpement-durable.svg">
    </section>

    <section id="Aide-Financiere-Transition" class="transition">
        <h3> Action Sociale </h3>
        <img src="../assets/img/Illustrations/aide-financiere.svg">
    </section>

    <section id="Ameliorer-Salon-Transition" class="transition">
        <h3> Améliorer mon Salon </h3>
        <img src="../assets/img/Illustrations/ameliorer-mon-salon.svg">
    </section>


    <section id="Mutuelle-Assurance-Transition" class="transition">
        <h3> Protection Sociale Prévoyance Santé </h3>
        <img src="../assets/img/Illustrations/assurance-mutuelle.svg">
    </section>

    <section id="Emploi-Formation-Transition" class="transition">
        <h3> Emploi / Formation </h3>
        <img src="../assets/img/Illustrations/emploi-formation.svg">
    </section>

</template>
<script>
    export default {

    }
</script>
<style lang="scss">
    $color-vert:#43E524;
    $color-bleu:#13ecbc;
    $color-rose:#ff748e;
    $color-gris:#9b9b9b;
    $color-rouge:#f76d6d;
    $color-jaune:#f6d12a;

    #Sante-Transition,
    #Mutuelle-Assurance-Transition {
        background: $color-rose;
    }

    #Dev-Durable-Transition,
    #Emploi-Formation-Transition {
        background: $color-vert;
    }

    #Aide-Financiere-Transition,
    #Ameliorer-Salon-Transition {
        background: $color-bleu;
    }

    .transition {
        display: flex;
        flex-direction: column;
        position: fixed;
        left: -100%;
        top: 0;
        min-height: 100vh;
        width: 100%;
        justify-content: center;
        align-items: center;
        transition: all ease-in 0.5s;
        z-index: 1000000;

        &.step1 {
            display: flex;
            left: 0;

            img,h3 {
                opacity: 1;
            }
        }

        &.step2 {
            display: flex;
            left: 100%;

            img,h3{
                opacity: 1;
            }
        }

        img {
            width: 60%;
            margin: 0 auto;
            opacity:0;
            transition: opacity 1s ease-in-out;
        }

        h3 {
            text-align: center;
            color: white;
            font-size: 80px;
            text-transform: uppercase;
            font-weight: 700;
            margin: 30px;
            opacity:0;
            transition: opacity 1s ease-in-out;
        }
    }
</style>