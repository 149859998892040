<template lang="">
<!--Santé-->
    <section id="Sante-Questions" class="questions Q-sante">
        <div class="reponses-interractions">
            <a href="#select" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Je souhaite</h2>
        </div>
        <div class="question-list">
            <a href="#S-Q1">1. Des conseils pour améliorer ma santé </a>
            <a href="#S-Q2">2. Améliorer mes gestes et postures</a>
            <!-- <a href="#S-Q3">3. Connaître les differents matériels ergonomiques</a>-->
        </div>
        <a href="#select" class="back">Revenir à la liste des thèmatiques</a>
    </section>



    <section id="S-Q1" class="questions Q-sante">
        <div class="reponses-interractions">
            <a href="#Sante-Questions" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Je souhaite</h2>
            <h3>Ameliorer ma Santé</h3>
        </div>
        <div class="question-list">
            <a href="#S-Q1-A1">1. Améliorer mes gestes et postures</a>
            <a href="#S-Q1-A2">2. Avoir une meilleure nutrition</a>
            <a href="#S-Q1-A3">3. Connaître des bonnes pratiques pour la santé au salon</a>
        </div>
        <a href="#Sante-Questions" class="back">Revenir aux questions</a>
    </section>



    <section id="S-Q2" class="questions Q-sante">
        <div class="reponses-interractions">
            <a href="#Sante-Questions" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Liste des maux</h2>
            <h3>Améliorer mes gestes et postures</h3>
        </div>
        <div class="question-list">
            <a href="#S-Q2-A1">1. Le dos </a>
            <a href="#S-Q2-A2">2. L'épaule et le coude</a>
            <a href="#S-Q2-A3">3. Les mains</a>
            <a href="#S-Q2-A4">4. Les jambes</a>
            <a href="#S-Q2-A5">5. L'appareil respiratoire</a>
            <a href="#S-Q2-A6">6. Le corps</a>
            <a href="#S-Q2-A7">7. Le mental</a>
        </div>
        <a href="#Sante-Questions" class="back">Revenir aux questions</a>
    </section>
    <!--DevDurable-->


    <section id="Dev-Durable-Questions" class="questions Q-devdurable">
        <div class="reponses-interractions">
            <a href="#select" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Label / Developpement Durable</h2>
        </div>
        <div class="question-list">
            <a href="#DD-Q1">1. Vous êtes labellisé ?</a>
            <a href="#DD-Q2">2. Vous n'êtes pas labellisé ?</a>
        </div>
        <a href="#select" class="back">Revenir à la liste des thèmatiques</a>
    </section>


    <section id="DD-Q1" class="questions Q-devdurable">
        <div class="reponses-interractions">
            <a href="#Dev-Durable-Questions" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Label / Developpement Durable</h2>
            <h3>Vous êtes labellisé</h3>
        </div>
        <div class="question-list">
            <a href="#DD-Q1-A1">1. Vous avez une question ?</a>
            <a href="#DD-Q1-A3">2. Vous souhaitez renouveler votre labellisation ?</a>

        </div>
        <a href="#Dev-Durable-Questions" class="back">Revenir aux questions</a>
    </section>
    <section id="DD-Q2" class="questions Q-devdurable">
        <div class="reponses-interractions">
            <a href="#Dev-Durable-Questions" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Label / Developpement Durable</h2>
            <h3>Vous n'êtes pas labellisé</h3>
        </div>
        <div class="question-list">
            <a href="#DD-Q2-A1">1. Un coiffeur indépendant ?</a>
            <a href="#DD-Q2-A2">2. Un salon employeur ?</a>

        </div>
        <a href="#Dev-Durable-Questions" class="back">Revenir aux questions</a>
    </section>

    <section id="DD-Q2-A1" class="questions Q-devdurable">
        <div class="reponses-interractions">
            <a href="#DD-Q2" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Vous n'êtes pas labellisé</h2>
            <h3>Un coiffeur indépendant</h3>
        </div>
        <div class="question-list">
            <a href="#DD-Q2-A1-B1">1. Qu'est ce que le label ?</a>
            <a href="#DD-Q2-A1-B2">2. Comment se labelliser ?</a>

        </div>
        <a href="#DD-Q2" class="back">Revenir aux questions</a>
    </section>

    <section id="DD-Q2-A1-B1" class="questions Q-devdurable">
        <div class="reponses-interractions">
            <a href="#DD-Q2-A1" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Label / Developpement Durable</h2>
            <h3>Qu'est ce que le label ?</h3>
        </div>
        <div class="question-list">
            <a href="#DD-Q2-A1-B1-C1">1. Fonctionnement de la labellisation</a>
            <a href="#DD-Q2-A1-B1-C2">2. Avantages / Bénéfice pour le salon & Collaboration</a>
            <a href="#DD-Q2-A1-B1-C3">3. Le Concept et démarche</a>

        </div>
        <a href="#DD-Q2-A1" class="back">Revenir aux questions</a>
    </section>
    <section id="DD-Q2-A2" class="questions Q-devdurable">
        <div class="reponses-interractions">
            <a href="#DD-Q2" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Vous n'êtes pas labellisé</h2>
            <h3>Un salon employeur</h3>
        </div>
        <div class="question-list">
            <a href="#DD-Q2-A2-B1">1. Qu'est ce que le label ?</a>
            <a href="#DD-Q2-A2-B2">2. Comment se labelliser ?</a>

        </div>
        <a href="#DD-Q2" class="back">Revenir aux questions</a>
    </section>

    <section id="DD-Q2-A2-B1" class="questions Q-devdurable">
        <div class="reponses-interractions">
            <a href="#DD-Q2-A2" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Label / Developpement Durable</h2>
            <h3>Qu'est ce que le label ?</h3>
        </div>
        <div class="question-list">
            <a href="#DD-Q2-A2-B1-C1">1. Fonctionnement de la labellisation</a>
            <a href="#DD-Q2-A2-B1-C2">2. Avantages / Bénéfice pour le salon & Collaboration</a>
            <a href="#DD-Q2-A2-B1-C3">3. Le Concept et démarche</a>

        </div>
        <a href="#DD-Q2-A2" class="back">Revenir aux questions</a>
    </section>
    <!--Aide financiere-->
    <section id="Aide-Financiere-Questions" class="questions Q-aidefinanciere">
        <div class="reponses-interractions">
            <a href="#select" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Vous souhaitez </h2>
        </div>
        <div class="question-list">
            <a href="#AF-Q2">1. Une aide sociale (logement...)</a>
            <a href="#AF-Q3">2. Une autre aide (aides spécifiques , exceptionnelles , situation familiale)</a>
        </div>
        <a href="#select" class="back">Revenir à la liste des thèmatiques</a>
    </section>
    <!--Emploi-->
    <section id="Emploi-Formation-Questions" class="questions Q-emploi">
        <div class="reponses-interractions">
            <a href="#select" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Vous êtes </h2>
        </div>
        <div class="question-list">
            <a href="#EF-Q1">1. à la recherche d'un emploi ? </a>
            <a href="#EF-Q2">2. un recruteur</a>
            <a href="#EF-Q3">3. un formateur / un enseignant ?</a>
        </div>
        <a href="#select" class="back">Revenir à la liste des thèmatiques</a>
    </section>
    <!--Mutuelle Assurance-->

    <section id="Mutuelle-Assurance-Questions" class="questions Q-mutuelle">
        <div class="reponses-interractions">
            <a href="#select" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Vous êtes </h2>
        </div>
        <div class="question-list">
            <a href="#MA-Q1">1. un chef d'entreprise employeur </a>
            <a href="#MA-Q2">2. un chef d'entreprise indépendante </a>
            <a href="#MA-Q3">3. un salarié / apprenti</a>
        </div>
        <a href="#select" class="back">Revenir à la liste des thèmatiques</a>
    </section>
    <section id="MA-Q1" class="questions Q-mutuelle">
        <div class="reponses-interractions">
            <a href="#Mutuelle-Assurance-Questions" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Vous souhaitez</h2>
        </div>
        <div class="question-list">
            <a href="#MA-Q1-A1">1. Vous avez une réclamation ?<br> Des questions sur un
                service ?</a>
            <a href="#MA-Q1-A2">2. Renseignement sur les services et offres de prévoyance,
                santé, retraite, complementaire santé </a>
        </div>
        <a href="#Mutuelle-Assurance-Questions" class="back">Revenir aux questions</a>
    </section>


    <section id="MA-Q2" class="questions Q-mutuelle">
        <div class="reponses-interractions">
            <a href="#Mutuelle-Assurance-Questions" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Vous souhaitez</h2>
        </div>
        <div class="question-list">
            <a href="#MA-Q2-A1">1. Vous avez une réclamation ?<br> Des questions sur un
                service ?</a>
            <a href="#MA-Q2-A2">2. Renseignement sur les services et offres de prévoyance,
                santé, retraite, complementaire santé </a>
        </div>
        <a href="#Mutuelle-Assurance-Questions" class="back">Revenir aux questions</a>
    </section>
    <section id="MA-Q3" class="questions Q-mutuelle">
        <div class="reponses-interractions">
            <a href="#Mutuelle-Assurance-Questions" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Vous souhaitez</h2>
        </div>
        <div class="question-list">
            <a href="#MA-Q3-A1">1. Vous avez une réclamation ?<br> Des questions sur un
                service ?</a>
            <a href="#MA-Q3-A2">2. Renseignement sur les services et offres de prévoyance,
                santé, retraite, complementaire santé </a>
        </div>
        <a href="#Mutuelle-Assurance-Questions" class="back">Revenir aux questions</a>
    </section>




    <!--Salon-->
    <section id="Ameliorer-Salon-Questions" class="questions Q-salon">
        <div class="reponses-interractions">
            <a href="#select" class="icon-return"><img src="../assets/img/Logo/arrow.svg"></a>
            <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg"></a>
        </div>
        <div class="titre">
            <h2>Listes des themes</h2>
        </div>
        <div class="question-list">
            <a href="#AMS-Q1">1. Energie </a>
            <a href="#AMS-Q2">2. Santé </a>
            <a href="#AMS-Q3">3. Produits</a>
            <a href="#AMS-Q4">4. Eau </a>
            <a href="#AMS-Q5">5. Déchets</a>
            <a href="#AMS-Q6">6. Air</a>
        </div>
        <a href="#select" class="back">Revenir à la liste des thèmatiques</a>
    </section>
</template>
<script>
    export default {

    }
</script>
<style lang="scss">
    $color-vert:#43E524;
    $color-bleu:#13ecbc;
    $color-rose:#ff748e;
    $color-gris:#9b9b9b;
    $color-rouge:#f76d6d;
    $color-jaune:#f6d12a;

    .reponses-interractions {
        display: flex;
        padding: 20px;
        justify-content: space-between;

        img {
            width: 32px;
            height: 32px;
        }

        .icon-return img {
            transform: rotate(180deg);
        }
    }


    .questions {
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 100vh;
        width: 100%;

        &.Q-salon,
        &.Q-aidefinanciere {
            background-color: $color-bleu;

            .question-list {
                a:hover {
                    color: $color-bleu;
                }
            }
        }

        &.Q-sante,
        &.Q-mutuelle {
            background-color: $color-rose;

            .question-list {
                a:hover {
                    color: $color-rose;
                }
            }

        }

        &.Q-devdurable,
        &.Q-emploi {
            background-color: $color-vert;

            .question-list {
                a:hover {
                    color: $color-vert;
                }
            }
        }

        .titre {
            margin-bottom: 30px;

            h2 {

                text-align: center;
                color: white;
                font-size: 50px;
                font-family: 'Montserrat', sans-serif;
                font-weight: bold;
                text-transform: uppercase;
                margin: 0;
            }

            h3 {
                text-align: center;
                color: white;
                font-size: 30px;
                font-family: "Montserrat", sans-serif;
                font-weight: 300;
                margin: 0;
            }
        }

        .question-list {
            display: flex;
            flex-direction: column;
            width: 70%;
            margin: 0 auto;

            a {
                color: white;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 18px;
                text-align: center;
                border: 2px solid white;
                display: block;
                padding: 20px 0px;
                margin-bottom: 15px;
                text-decoration: none;
                width: 100%;

                &:hover {
                    background-color: white;
                }
            }
        }
    }

    #S-Q2 {
        .question-list a {
            margin-bottom: 10px;
        }
    }
</style>