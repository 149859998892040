<template lang="">
    <transition name="show">
        <div id="home">
            <div class="logos">
                <img src="../assets/img/Logo/Logo-moncoiffeursengage.svg" alt="Logo mon coiffeur s'engage"
                    class="home-gauche">
                <img src="../assets/img/Logo/pole-coiffure_White.svg" alt="Logo AG2R LA MONDIALE Pole Coiffure" class="home-droite">

            </div>
            <!-- <img src="../assets/img/Illustrations/Illustration-1_animated.svg" alt="illustration home"
                class="home-centre">-->
                <div class="home-centre">
            <lottie-animation
             path="../json/anim-home.json"
              :loop="true" 
              :autoPlay="true"
            /></div>
            <!--  <video autoplay loop>

                <source src="../assets/img/Illustrations/Composition_1_2.webm" type="video/webm">
            </video>-->

            <h1>Bonjour ! Besoin d'aide ?</h1>
            <a href="#select" class="start">Commencer</a>
        </div>
    </transition>
</template>
<script>
    import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

    export default {
        components: {
            LottieAnimation
        }
    }
</script>
<style lang="scss">
    $color-vert:#43E524;
    $color-bleu:#13ecbc;
    $color-rose:#ff748e;
    $color-gris:#9b9b9b;
    $color-rouge:#f76d6d;
    $color-jaune:#f6d12a;

    #home {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 100vh;
        width: 100%;
        .home-centre {
            pointer-events: none;
            width: 100%;
            margin: 0 auto;
            height: 70vh;
            margin-top: -5vh;
        }

        .logos {
            position: absolute;
            top: 15px;
            left: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100%;

            img {
                width: 18%;
            }
        }

        h1 {
            font-family: 'PT Serif', serif;
            color: white;
            font-size: 80px;
            text-align: center;
            margin: 0;

        }

        a {
            font-family: 'Montserrat', sans-serif;
            color: white;
            text-transform: uppercase;
            text-align: center;
            border: white solid 2px;
            padding: 13px 50px;
            font-weight: 800;
            font-size: 16px;
            transition: all 1s;
            -webkit-transition: all 1s;
            width: 400px;
            margin: 0 auto;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                background-color: white;
                color: $color-vert;
            }
        }
    }

    .show-enter-active,
    .show-leave-enter {
        transform: translateX(0);
        transition: all .3s linear;
    }

    .show-enter,
    .show-leave-to {
        transform: translateX(100%);
    }
</style>