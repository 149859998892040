<template>
<div id="modal-VR" class="modal" v-if="activeVR">
            <!-- Modal content -->
            <div class="modal-content modal-content-VR">
                <span class="close" v-on:click="toggleModalVR">×</span>
                <img src="../assets/img/Illustrations/realite-virtuelle.svg">
                <h2>Essayez-vous à l'expérience de Réalité Virtuelle</h2>
                <h3>Rendez-vous sur le stand à l'espace VR</h3>
            </div>
        </div>
</template>
<script>
export default {
        name: 'formemail',
        props: ["name","activeVR", "toggleModalVR"],
    }
</script>
<style lang="scss" scoped>
  $color-vert:#43E524;
  $color-bleu:#13ecbc;
  $color-rose:#ff748e;
  $color-gris:#9b9b9b;
  $color-rouge:#f76d6d;
  $color-jaune:#f6d12a;

 .modal {
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.5) !important;
        -webkit-animation-name: fadeIn !important;
        -webkit-animation-duration: 1s !important;
        animation-name: fadeIn;
        animation-duration: 1s;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .close{
        font-size: 50px;
        position: absolute;
        top: 0;
        right: 0;
        color: #9b9b9b;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }
    .modal-content {
        z-index: 10000;
        position: fixed;
        background-color: #fefefe;
        width: 50% !important;
        -webkit-animation-name: slideIn !important;
        -webkit-animation-duration: 1s !important;
        animation-name: slideIn !important;
        animation-duration: 1s !important;
    }
        h2 {
        text-align: center;
        color: $color-rouge;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: 25px;
        margin: 0;

    }

    h3 {
        text-align: center;
        color: $color-rouge;
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
        margin: 0;      
        margin-bottom: 40px;
        font-weight:400
    }
    img{
        width: 350px;
        height: auto;
        margin-left: 20%;
        margin-bottom: 40px;
    }
@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

</style>