<template lang="">
    <div v-bind:id="'modal-'+ idform" class="modal form-question">
        <!-- Modal content -->
        <div class="modal-content">
            <span class="close" v-on:click="toggleModalQuestion">&times;</span>
            <div class="modal-form">
                <h2>Posez votre question</h2>
                <form method="post" v-bind:id="'form-'+ idform" autocomplete="off" v-on:submit.prevent="submit">
                    <table>
                        <tr>
                            <td>
                                <input type="text" class="nom-form" name="nom" placeholder="Entrez votre Nom*" required
                                    v-model="nom_value" />
                            </td>
                            <td>
                                <input type="text" class="prenom-form" name="prenom" placeholder="Entrez votre prenom*"
                                    required v-model="prenom_value" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" class="ville-form" name="ville-salon"
                                    placeholder="Entrez la ville de votre salon" v-model="ville_value" />
                            </td>
                            <td>
                                <input type="text" class="salon-form" name="nom-salon"
                                    placeholder="Entrez le nom de votre salon" v-model="salon_value" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" class="telephone-form" name="telephone"
                                    placeholder="Entrez votre n° telephone*" v-model="tel_value" required />
                            </td>
                            <td>
                                <select name="situation" v-model="situation_value">
                                    <option value="Vous êtes ?">Vous êtes ?</option>
                                    <option value="Apprenti">Apprenti</option>
                                    <option value="Salarie">Salarié</option>
                                    <option value="Chef d'Entreprise">Chef d'Entreprise</option>
                                    <option value="Indépendant">Indépendant</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <input type="email" class="email-form" name="email"
                                    placeholder="Entrez votre adresse e-mail*" required v-model="email_value" />
                            </td>
                        </tr>
                    </table>

                    <textarea rows="4" cols="50" class="question-form" name="question"
                        placeholder="Posez votre question*" required v-model="question_value"></textarea>
                    <div class="RGPD-form">
                        <input type="checkbox" name="RGPD" class="input-RGPD" v-model="rgpd_value" required>

                        <label for="RGPD">En soumettant ce formulaire, j’accepte que les informations saisies
                            soient utilisées,
                            exploitées, traitées dans le cadre de cette demande.<span style="color:red">*</span></label>
                    </div>
                    <input type="submit" value="Valider" class="submit" v-on:click="addQuestion()" />

                </form>
            </div>
            <div class="modal-form-validation">
                <img src="../assets/img/Illustrations/email-envoye.svg" class="img-valid-mail">
                <h2>Formulaire envoyé</h2>
                <a href="#home" class="form-retour" v-on:click="toggleModalQuestion">Retour à l'accueil</a>
            </div>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery'
    import axios from 'axios'


    export default {
        name: 'formemail',
        props: {
            idform: {
                type: String,
            },
            activeQuestion: {
                type: Boolean,
            },
            toggleModalQuestion: {
                type: Function,
            }
        },
        data() {
            return {
                nom_value: "",
                prenom_value: "",
                ville_value: "",
                salon_value: "",
                tel_value: "",
                situation_value: "Vous êtes ?",
                email_value: "",
                numadd_value: "question_label",
                question_value: "",
                rgpd_value: "OK",
            }
        },
        methods: {
            addQuestion: function () {
                if (this.email_value != "") {
                    axios.post("./ajax/ajaxfilequestion.php", {
                        action: 'insert',
                        id_form: this.idform,
                        nom_value: this.nom_value,
                        prenom_value: this.prenom_value,
                        ville_value: this.ville_value,
                        salon_value: this.salon_value,
                        tel_value: this.tel_value,
                        situation_value: this.situation_value,
                        email_value: this.email_value,
                        numadd_value: this.numadd_value,
                        question_value: this.question_value,
                        rgpd_value: "OK",
                    }).then(function (response) {
                        $('.form-question form').trigger("reset");
                        $('.form-question .modal-form-validation').show();
                        $('.form-question .modal-form').hide();
                        console.log(response);
                    })
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    $color-vert:#43E524;
    $color-bleu:#13ecbc;
    $color-rose:#ff748e;
    $color-gris:#9b9b9b;
    $color-rouge:#f76d6d;
    $color-jaune:#f6d12a;

    .modal {
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.5) !important;
        -webkit-animation-name: fadeIn !important;
        -webkit-animation-duration: 1s !important;
        animation-name: fadeIn;
        animation-duration: 1s;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .close {
        font-size: 50px;
        position: absolute;
        top: 0;
        right: 0;
        color: #9b9b9b;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    .modal-content {
        z-index: 10000;
        position: fixed;
        background-color: #fefefe;
        width: 50% !important;
        -webkit-animation-name: slideIn !important;
        -webkit-animation-duration: 1s !important;
        animation-name: slideIn !important;
        animation-duration: 1s !important;

        img {
            margin: 20px auto;
            width: 150px;
            display: block;

        }

        .img-valid-mail {
            width: 350px;
            height: auto;
            margin: 0 auto;
        }

        h2 {
            text-align: center;
            color: $color-rouge;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            font-size: 25px;

        }

        h3 {
            text-align: center;
            color: $color-rouge;
            font-size: 20px;
            font-family: 'Montserrat', sans-serif;
        }

        i {
            text-align: center;
            color: $color-gris;
            font-style: normal;
            display: block;
        }
        table{
            width: 100%;
        }

        input {
            margin-left: 5%;
            width: 90%;
            margin-top: 10px;
            padding: 12px;
            margin-bottom: 10px;
            font-size: 12px;
            border-radius: 0px !important;
            border: solid 1px #9b9b9b !important;
            text-shadow: none !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            overflow: visible;
            font-family: Montserrat, sans-serif;
            font-size: 14px;

        }

        .email-form {
            width: 95%;
            margin-left: 2.5%;
        }

        select {
            margin-left: 5%;
            width: 90%;
            padding: 10px;
            border: solid 1px $color-gris !important;
            color: $color-gris;
            font-family: Montserrat, sans-serif;
             font-size: 14px;


        }

        .RGPD-form {
            display: flex;
        }

        input[type="checkbox"] {
            width: 20px;
            height: 20px;
            appearance: checkbox !important;
            -moz-appearance: checkbox;
            -webkit-appearance: checkbox;
            margin: 0 2.5%;
        }

        label {
            width: 100%;
            margin-bottom: .5rem;
            font-size: 14px;

        }

        textarea {
            width: 95%;
            margin-left: 2.5%;
            max-width: 95%;
            min-width: 95%;
            min-height: 75px;
            max-height: 75px;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            padding: 10px;
        }

        .submit {
            background-color: $color-rouge;
            color: white;
            border: solid 1px $color-rouge !important;
            font-weight: bold;
            transition: all ease-in-out 0.5s;
            -webkit-appearance: none;

            &:hover {
                background-color: white;
                color: $color-rouge;
                -webkit-appearance: none;
            }

        }

        .modal-form-validation {
            display: none;

            h2 {
                margin-top: 25px;
            }

            a {
                text-decoration: none;
                font-size: 20px;
                text-align: center;
                display: block;
                color: $color-gris;
                margin-bottom: 25px;
                margin-top: 25px;

                &:hover {
                    color: rgb(55, 55, 55);
                    text-decoration: none;
                }
            }

        }
    }

    @-webkit-keyframes fadeIn {
        from {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }
</style>