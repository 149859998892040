<template>
  <section id="home-section">
    <Home />
    <selectsection />
  </section>
  <question />
  <Reponseslist />


</template>

<script>
  import Reponseslist from './components/Reponseslist.vue'
  import Home from './components/home.vue'
  import question from './components/question.vue'
  import selectsection from './components/select.vue'


  export default {
    name: 'App',
    components: {
      Reponseslist,
      Home,
      selectsection,      
      question
    }
  }
</script>

<style lang="scss">
    $color-vert:#43E524;
    $color-bleu:#13ecbc;
    $color-rose:#ff748e;
    $color-gris:#9b9b9b;
    $color-rouge:#f76d6d;
    $color-jaune:#f6d12a;

html {
    -webkit-text-size-adjust: none;
    overflow: hidden;
}

*, ::after, ::before {
    box-sizing: border-box;
}
  body {
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    overflow: hidden;
    width: 100%;
    position:fixed;
  }

  #home-section {
    scroll-behavior: smooth;
    transition: all 500ms ease;
    -webkit-overflow-scrolling: touch;
    background-image: -moz-linear-gradient(left, #43E524, #13ecbc);
    background: linear-gradient(to right, #43E524, #13ecbc);
  }

  #avertissement {
    display: none;
}

@media only screen and (orientation:portrait) {
    #app {
        display: none;
    }

    #avertissement {
        display: block;

        h1 {
            text-align: center;
            margin-top: 50%;
            color: $color-rouge;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 40px;
        }
    }
}

@media only screen and (orientation:landscape) {
    #avertissement {
        display: none;
    }
}



</style>