<template>

  <section class="reponses-list">
    <div v-for="reponse in reponses" :key="reponse.ID" class="reponse" v-bind:class="[reponse.Section,reponse.ID]"
      v-bind:id="reponse.ID">

      <div class="reponses-interractions">
        <a v-bind:href="reponse.return" class="icon-return"><img src="../assets/img/Logo/arrow.svg"
            v-on:click="textToTop()"></a>
        <a href="#home" class="icon-home"><img src="../assets/img/Logo/home.svg" v-on:click="textToTop()"></a>
      </div>
      <h2>{{ reponse.Categories }}</h2>
      <h3>{{ reponse.Question }}</h3>
      <div class="reponse-bloc" v-bind:class="{ 'grid-1' : reponse.Visuel_exist === false}">
        <div class="reponse-text">
          <p v-html="reponse.Reponse"></p>

        </div>

        <div class="reponse-image" v-show="reponse.Visuel_exist">
          <img v-bind:src="require(`../assets/${reponse.Visuel}`)" v-bind:alt="reponse.Question">
        </div>
        <div class="scroll-text"><img src="../assets/img/Image/scrolling.svg"></div>
      </div>

      <div class="reponse-buttons">
        <button v-show="reponse.modal_email" class="btn-modal-email" v-on:click="toggleModalEmail(reponse.ID)">
          <i class="fas fa-envelope"></i><span>Recevoir ces informations par e-mail</span>
        </button>
        <button v-show="reponse.modal_VR" class="btn-modal-VR" v-on:click="toggleModalVR()">
          <i class="fas fa-vr-cardboard"></i><span> Découvrez l'expérience de Réalité Virtuelle</span>
        </button>
        <button v-show="reponse.modal_AG2R" class="btn-modal-AG2R" v-on:click="toggleModalAG2R(reponse.ID)">
          <i class="fas fa-envelope"></i> <span>Posez votre question /Soumettez une reclamation</span>
        </button>
        <button v-show="reponse.modal_question" class="btn-modal-question" v-on:click="toggleModalQuestion(reponse.ID)">
          <i class="fas fa-envelope" aria-hidden="true"></i> <span>Posez votre question</span>
        </button>

      </div>
      <a v-bind:href="reponse.return" class="btn-select" v-on:click="textToTop()">{{ reponse.return_texte }}</a>
    </div>


    <modalEmail v-if="activeEmail" v-bind:idform="idform" :toggleModalEmail="toggleModalEmail" />
    <modalVR :activeVR="activeVR" :toggleModalVR="toggleModalVR" />
    <modalAG2R :activeAGR="activeAG2R" :toggleModalAG2R="toggleModalAG2R" />
    <modalquestion v-if="activequestion" v-bind:idform="idform" :activequestion="activequestion"
      :toggleModalQuestion="toggleModalQuestion" />
  </section>


</template>
<script>
  import reponse from "../json/reponses.json";
  import modalEmail from '../components/modalEmail.vue';
  import modalVR from '../components/modalVR.vue';
  import modalAG2R from '../components/modalAG2R.vue';
  import modalquestion from '../components/modalquestion.vue';

  import $ from 'jquery'


  export default {
    name: "reponseslist",
    components: {
      modalEmail,
      modalVR,
      modalAG2R,
      modalquestion,
    },
    data() {
      return {
        reponses: JSON.parse(JSON.stringify(reponse.reponses)),
        activeEmail: false,
        activeVR: false,
        activeAG2R: false,
        activequestion: false,
        idform: null
      };
    },
    methods: {
      toggleModalEmail: function (prop) {
        this.activeEmail = !this.activeEmail;
        this.idform = prop;
        $('.form_email .modal-form-validation').hide();
        $('.form_email .modal-form').show();
        $('.reponse-text').toggleClass("noscroll");
        setTimeout(function () {
          $(".reponse-text").scrollTop(0)
        }, 200);
      },
      toggleModalQuestion: function (propQ) {
        this.activequestion = !this.activequestion;
        this.idform = propQ;
        $('.form-question .modal-form-validation').hide();
        $('.form-question .modal-form').show();
        $('.reponse-text').toggleClass("noscroll");
        setTimeout(function () {
          $(".reponse-text").scrollTop(0)
        }, 200);
      },

      toggleModalVR: function () {
        this.activeVR = !this.activeVR;
        $('.reponse-text').toggleClass("noscroll");
      },
      toggleModalAG2R: function (prop) {
        this.activeAG2R = !this.activeAG2R;
        this.idform = prop;
        $('.reponse-text').toggleClass("noscroll");
      },
      textToTop: function () {
        setTimeout(function () {
          $(".reponse-text").scrollTop(0)
        }, 200);
      },
      scrollicon: function () {
        $('.reponse-text').each(function () {
          if ($(this).prop('scrollHeight') > $(this).height()) {
            $(this).parent().parent().find('.scroll-text').show();
          }
        })
      }
    },
    mounted() {
      this.scrollicon();
    }



  };
</script>
<style lang="scss">
  $color-vert:#43E524;
  $color-bleu:#13ecbc;
  $color-rose:#ff748e;
  $color-gris:#9b9b9b;
  $color-rouge:#f76d6d;
  $color-jaune:#f6d12a;


  .reponse {
    width: 100%;
    height: 100vh;
    min-height: 1024px;

    h2 {
      text-align: center;
      color: white;
      font-size: 50px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
    }

    h3 {
      text-align: center;
      color: white;
      font-size: 30px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      margin: 0;
    }

    /*Reponse*/
    .reponse-bloc {
      display: grid;
      padding: 15px 5vw;
      position: relative;
      grid-template-columns: 1fr 1fr;

      &.grid-1 {
        grid-template-columns: 1fr;
      }

    }

    .reponse-text {
      font-family: 'Montserrat', sans-serif;
      color: white;
      font-size: 17px;
      text-align: left;
      height: 420px;
      overflow: auto;
      font-weight: 500;
      scroll-behavior: smooth;
      padding-right: 10px;

      &.noscroll {
        overflow: hidden !important;
      }

      strong {
        font-weight: bold;
      }

    }

    .reponse-image {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 430px;
      }
    }

    .scroll-text {
      display: none;
      position: absolute;
      left: 25%;
      transform: translateX(0%);
      bottom: -30px;
      transform: translateY(-20px);


      img {
        height: 40px;
        width: 40px;
        animation: updown 2s linear infinite alternate;
      }
    }

    @keyframes updown {
      from {
        transform: translateY(-5px);
      }

      to {
        transform: translateY(5px);
      }
    }

    .grid-1 .scroll-text {
      left: 50%;
      transform: translateX(-50%);
    }

    .reponse-buttons {
      display: flex;
      margin: 35px 14vw;

      button {
        border: solid white 1px;
        padding: 5px;
        background-color: rgba(255, 255, 255, 0);
        margin-left: 15px;
        font-size: 16px;
        width: 100%;
        color: white;
        padding-right: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;


        i {
          font-size: 30px;
          padding-left: 12px;
        }

        span {
          padding-left: 10px;
        }

        &:hover {
          background-color: white;
        }
      }
    }

    .btn-select {
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      display: block;
      text-decoration: none;


      &:hover {
        color: white !important;
        text-decoration: none;
        background: none;
      }
    }

    .infoplus {
      padding: 15px;
      position: relative;
      z-index: 3;

      &:before {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.15);
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        z-index: -1;
      }

      .titre-infoplus {
        font-weight: bold;
        margin-bottom: 10px;
        display: block;

        i {
          margin-right: 15px
        }
      }
    }
  }

  .Sante button:hover,
  .Mutuelle button:hover {
    color: $color-rose;
    text-decoration: none;
  }

  .DevDurable button:hover,
  .Emploi button:hover {
    color: $color-vert;
    text-decoration: none;
  }

  .Salon button:hover,
  .AideFinanciere button:hover {
    color: $color-bleu;
    text-decoration: none;
  }

  .aidepreciseo button:hover {
    color: $color-jaune;
    text-decoration: none;
  }

  .Sante,
  .Mutuelle {
    background-color: $color-rose;
  }

  .DevDurable,
  .Emploi {
    background-color: $color-vert;
  }

  .Salon,
  .AideFinanciere {
    background-color: $color-bleu;
  }
</style>